import React from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  open: false,
  imageUrl: '',

};

const ModalContext = React.createContext(defaultState);

class ModalProvider extends React.Component {
  state = {
    open: false,
    imageUrl: ''
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  close = () => {
    this.setState({ open: false, imageUrl: '' });
  };

  open = (imageUrl, imageAlt, imageCaption) => {
    this.setState({ open: true, imageUrl: imageUrl});
  };




  render() {
    const { children } = this.props;
    const { open, imageUrl } = this.state;
    return (
      <ModalContext.Provider
        value={{
          open,
          imageUrl,
          closeModal: this.close,
          openModal: this.open,
        }}
      >
        {children}
      </ModalContext.Provider>
    );
  }
}


export default ModalContext;

export { ModalProvider };