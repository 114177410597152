// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-background-js": () => import("./../src/pages/background.js" /* webpackChunkName: "component---src-pages-background-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-design-js": () => import("./../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-fotoshoots-js": () => import("./../src/pages/fotoshoots.js" /* webpackChunkName: "component---src-pages-fotoshoots-js" */),
  "component---src-pages-herstellung-js": () => import("./../src/pages/herstellung.js" /* webpackChunkName: "component---src-pages-herstellung-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kurse-js": () => import("./../src/pages/kurse.js" /* webpackChunkName: "component---src-pages-kurse-js" */),
  "component---src-pages-kursepro-js": () => import("./../src/pages/kursepro.js" /* webpackChunkName: "component---src-pages-kursepro-js" */),
  "component---src-pages-referenzen-js": () => import("./../src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-special-js": () => import("./../src/pages/special.js" /* webpackChunkName: "component---src-pages-special-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-verleih-js": () => import("./../src/pages/verleih.js" /* webpackChunkName: "component---src-pages-verleih-js" */),
  "component---src-pages-wedding-js": () => import("./../src/pages/wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */),
  "component---src-pages-zweithaar-js": () => import("./../src/pages/zweithaar.js" /* webpackChunkName: "component---src-pages-zweithaar-js" */)
}

